/* tslint:disable */
/* eslint-disable */
/**
 * crowdtech
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AboutInformation
 */
export interface AboutInformation {
    /**
     * 
     * @type {string}
     * @memberof AboutInformation
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AboutInformation
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdminJobOffer
 */
export interface AdminJobOffer {
    /**
     * 
     * @type {number}
     * @memberof AdminJobOffer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminJobOffer
     */
    'salesforceJobId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminJobOffer
     */
    'status': AdminJobOfferStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminJobOffer
     */
    'title': string;
}

export const AdminJobOfferStatusEnum = {
    Open: 'open',
    Close: 'close'
} as const;

export type AdminJobOfferStatusEnum = typeof AdminJobOfferStatusEnum[keyof typeof AdminJobOfferStatusEnum];

/**
 * 
 * @export
 * @interface ArticlesInformation
 */
export interface ArticlesInformation {
    /**
     * 
     * @type {string}
     * @memberof ArticlesInformation
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesInformation
     */
    'url': string;
}
/**
 * @type BadRequestError
 * @export
 */
export type BadRequestError = ContractExtensionRequestBadRequest | DuplicateSkillError | UpdateClientStaffPasswordBadRequest | VerifyClientStaffEmailBadRequest;

/**
 * 
 * @export
 * @interface Bill
 */
export interface Bill {
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'status': BillStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'targetMonth': string;
    /**
     * 
     * @type {BillProject}
     * @memberof Bill
     */
    'project': BillProject;
    /**
     * 
     * @type {BillInvoice}
     * @memberof Bill
     */
    'invoice': BillInvoice;
    /**
     * 
     * @type {BillUser}
     * @memberof Bill
     */
    'user': BillUser;
}

export const BillStatusEnum = {
    ConfirmRequested: 'confirmRequested',
    Confirmed: 'confirmed',
    Paid: 'paid'
} as const;

export type BillStatusEnum = typeof BillStatusEnum[keyof typeof BillStatusEnum];

/**
 * 
 * @export
 * @interface BillCount
 */
export interface BillCount {
    /**
     * 
     * @type {number}
     * @memberof BillCount
     */
    'confirmRequested'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillCount
     */
    'confirmed'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillCount
     */
    'paid'?: number;
}
/**
 * 
 * @export
 * @interface BillDetail
 */
export interface BillDetail {
    /**
     * 
     * @type {string}
     * @memberof BillDetail
     */
    'targetMonth': string;
    /**
     * 
     * @type {number}
     * @memberof BillDetail
     */
    'subtotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof BillDetail
     */
    'consumptionTaxRate': number;
    /**
     * 
     * @type {number}
     * @memberof BillDetail
     */
    'consumptionTaxAmount': number;
    /**
     * 
     * @type {number}
     * @memberof BillDetail
     */
    'expenseAmount': number;
    /**
     * 
     * @type {BillDetailInvoice}
     * @memberof BillDetail
     */
    'invoice': BillDetailInvoice;
}
/**
 * 
 * @export
 * @interface BillDetailInvoice
 */
export interface BillDetailInvoice {
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoice
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoice
     */
    'billedOn': string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoice
     */
    'billLimitedOn': string;
    /**
     * 
     * @type {number}
     * @memberof BillDetailInvoice
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoice
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {Array<BillDetailInvoiceBreakdowns>}
     * @memberof BillDetailInvoice
     */
    'breakdowns': Array<BillDetailInvoiceBreakdowns>;
}
/**
 * 
 * @export
 * @interface BillDetailInvoiceBreakdowns
 */
export interface BillDetailInvoiceBreakdowns {
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoiceBreakdowns
     */
    'itemName': string | null;
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoiceBreakdowns
     */
    'workerName': string | null;
    /**
     * 
     * @type {number}
     * @memberof BillDetailInvoiceBreakdowns
     */
    'unitPrice': number | null;
    /**
     * 
     * @type {string}
     * @memberof BillDetailInvoiceBreakdowns
     */
    'quantity': string | null;
    /**
     * 
     * @type {number}
     * @memberof BillDetailInvoiceBreakdowns
     */
    'amount': number | null;
}
/**
 * 
 * @export
 * @interface BillInvoice
 */
export interface BillInvoice {
    /**
     * 
     * @type {string}
     * @memberof BillInvoice
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof BillInvoice
     */
    'billedOn': string;
    /**
     * 
     * @type {string}
     * @memberof BillInvoice
     */
    'billLimitedOn': string;
    /**
     * 
     * @type {number}
     * @memberof BillInvoice
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof BillInvoice
     */
    'invoiceNumber': string;
}
/**
 * 
 * @export
 * @interface BillProject
 */
export interface BillProject {
    /**
     * 
     * @type {string}
     * @memberof BillProject
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface BillUser
 */
export interface BillUser {
    /**
     * 
     * @type {string}
     * @memberof BillUser
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface BillYear
 */
export interface BillYear {
    /**
     * 
     * @type {number}
     * @memberof BillYear
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof BillYear
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ClientContract
 */
export interface ClientContract {
    /**
     * 
     * @type {string}
     * @memberof ClientContract
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientContract
     */
    'userFullName'?: string;
}
/**
 * 
 * @export
 * @interface ClientContractExtensionRequest
 */
export interface ClientContractExtensionRequest {
    /**
     * 
     * @type {ContractExtensionRequest1}
     * @memberof ClientContractExtensionRequest
     */
    'contractExtensionRequest'?: ContractExtensionRequest1;
}
/**
 * 
 * @export
 * @interface ContractExtensionContractRequest
 */
export interface ContractExtensionContractRequest {
    /**
     * 契約延長の期限日
     * @type {string}
     * @memberof ContractExtensionContractRequest
     */
    'deadline': string;
}
/**
 * 
 * @export
 * @interface ContractExtensionRequest
 */
export interface ContractExtensionRequest {
    /**
     * 
     * @type {number}
     * @memberof ContractExtensionRequest
     */
    'id': number;
    /**
     * 
     * @type {QuestionnaireStatus}
     * @memberof ContractExtensionRequest
     */
    'status': QuestionnaireStatus;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequest
     */
    'expiresAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequest
     */
    'category': ContractExtensionRequestCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequest
     */
    'url': string;
    /**
     * 
     * @type {ContractExtensionRequestProject}
     * @memberof ContractExtensionRequest
     */
    'project': ContractExtensionRequestProject;
    /**
     * 
     * @type {BillUser}
     * @memberof ContractExtensionRequest
     */
    'user': BillUser;
    /**
     * 
     * @type {Array<ExtensionSurveyAnswer>}
     * @memberof ContractExtensionRequest
     */
    'answers'?: Array<ExtensionSurveyAnswer>;
    /**
     * 
     * @type {ContractExtensionContractRequest}
     * @memberof ContractExtensionRequest
     */
    'clientContractExtensionContractRequest': ContractExtensionContractRequest;
}

export const ContractExtensionRequestCategoryEnum = {
    ContractExtensionRequest: 'contractExtensionRequest'
} as const;

export type ContractExtensionRequestCategoryEnum = typeof ContractExtensionRequestCategoryEnum[keyof typeof ContractExtensionRequestCategoryEnum];

/**
 * 
 * @export
 * @interface ContractExtensionRequest1
 */
export interface ContractExtensionRequest1 {
    /**
     * 
     * @type {ClientContract}
     * @memberof ContractExtensionRequest1
     */
    'contract'?: ClientContract;
    /**
     * 
     * @type {Array<ExtensionSurveyAnswerItem>}
     * @memberof ContractExtensionRequest1
     */
    'extensionSurveyAnswer'?: Array<ExtensionSurveyAnswerItem>;
}
/**
 * 延長アンケート更新エラー
 * @export
 * @interface ContractExtensionRequestBadRequest
 */
export interface ContractExtensionRequestBadRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequestBadRequest
     */
    'operationId': ContractExtensionRequestBadRequestOperationIdEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ContractExtensionRequestBadRequest
     */
    'errors': { [key: string]: object; };
}

export const ContractExtensionRequestBadRequestOperationIdEnum = {
    UpdateClientContractExtensionRequest: 'updateClientContractExtensionRequest'
} as const;

export type ContractExtensionRequestBadRequestOperationIdEnum = typeof ContractExtensionRequestBadRequestOperationIdEnum[keyof typeof ContractExtensionRequestBadRequestOperationIdEnum];

/**
 * 
 * @export
 * @interface ContractExtensionRequestProject
 */
export interface ContractExtensionRequestProject {
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequestProject
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequestProject
     */
    'startOn': string;
    /**
     * 
     * @type {string}
     * @memberof ContractExtensionRequestProject
     */
    'endOn': string;
}
/**
 * スキル重複エラー
 * @export
 * @interface DuplicateSkillError
 */
export interface DuplicateSkillError {
    /**
     * 
     * @type {string}
     * @memberof DuplicateSkillError
     */
    'operationId': DuplicateSkillErrorOperationIdEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateSkillError
     */
    'error': string;
}

export const DuplicateSkillErrorOperationIdEnum = {
    UpdateUserSkillRelations: 'updateUserSkillRelations'
} as const;

export type DuplicateSkillErrorOperationIdEnum = typeof DuplicateSkillErrorOperationIdEnum[keyof typeof DuplicateSkillErrorOperationIdEnum];

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'operationId': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface ExtensionSurveyAnswer
 */
export interface ExtensionSurveyAnswer {
    /**
     * 
     * @type {string}
     * @memberof ExtensionSurveyAnswer
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionSurveyAnswer
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface ExtensionSurveyAnswerItem
 */
export interface ExtensionSurveyAnswerItem {
    /**
     * 
     * @type {any}
     * @memberof ExtensionSurveyAnswerItem
     */
    'id'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ExtensionSurveyAnswerItem
     */
    'answer'?: any | null;
    /**
     * 
     * @type {ExtensionSurveyQuestion}
     * @memberof ExtensionSurveyAnswerItem
     */
    'extensionSurveyQuestion'?: ExtensionSurveyQuestion;
}
/**
 * 
 * @export
 * @interface ExtensionSurveyQuestion
 */
export interface ExtensionSurveyQuestion {
    /**
     * 
     * @type {number}
     * @memberof ExtensionSurveyQuestion
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionSurveyQuestion
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtensionSurveyQuestion
     */
    'format'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtensionSurveyQuestion
     */
    'optionsForSelect'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtensionSurveyQuestion
     */
    'question'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionSurveyQuestion
     */
    'displayFlag'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'passwordConfirmation': string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject1
     */
    'agreePrivacyPolicy': boolean;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    'word'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'workingDays'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'workStyles'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'categoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'prefectureIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'skillIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject3
     */
    'appealIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'registrated_number': string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {Array<UserSkillRelation>}
     * @memberof InlineObject5
     */
    'userSkillRelations'?: Array<UserSkillRelation>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    'name': string;
    /**
     * 
     * @type {Array<JobOfferCategory>}
     * @memberof InlineResponse2005
     */
    'categories': Array<JobOfferCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    'operationId': string;
    /**
     * 
     * @type {InlineResponse400Errors}
     * @memberof InlineResponse400
     */
    'errors'?: InlineResponse400Errors;
}
/**
 * 
 * @export
 * @interface InlineResponse400Errors
 */
export interface InlineResponse400Errors {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse400Errors
     */
    'query'?: Array<string>;
}
/**
 * 
 * @export
 * @interface JobOffer
 */
export interface JobOffer {
    /**
     * 
     * @type {number}
     * @memberof JobOffer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof JobOffer
     */
    'maxUnitPrice': number;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'officeLocationPrefecture'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'officeLocation': string;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'requiredWorkingDays'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'productDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOffer
     */
    'requirement'?: string;
    /**
     * 
     * @type {Array<JobOfferMasterSkills>}
     * @memberof JobOffer
     */
    'masterSkills': Array<JobOfferMasterSkills>;
    /**
     * 
     * @type {JobOfferMasterSkills}
     * @memberof JobOffer
     */
    'occupation': JobOfferMasterSkills;
}
/**
 * 
 * @export
 * @interface JobOfferCategory
 */
export interface JobOfferCategory {
    /**
     * 
     * @type {number}
     * @memberof JobOfferCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof JobOfferCategory
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface JobOfferDetail
 */
export interface JobOfferDetail {
    /**
     * 
     * @type {number}
     * @memberof JobOfferDetail
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof JobOfferDetail
     */
    'jobOfferId': number;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'detailedTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'workingHours'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'specificWorkContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'relatedServicesProducts'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'requirementSkills'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferDetail
     */
    'commercialDistribution'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'matchingWorkingDays': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'matchingResidentDays': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'developmentLanguages': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'frameworks': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'databases': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'tools': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'infrastructures': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'designs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobOfferDetail
     */
    'paymentMethods': Array<string>;
}
/**
 * 
 * @export
 * @interface JobOfferMasterSkills
 */
export interface JobOfferMasterSkills {
    /**
     * 
     * @type {string}
     * @memberof JobOfferMasterSkills
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface JobOfferSummary
 */
export interface JobOfferSummary {
    /**
     * 
     * @type {number}
     * @memberof JobOfferSummary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof JobOfferSummary
     */
    'workStyleName': string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferSummary
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof JobOfferSummary
     */
    'maxUnitPrice': number;
    /**
     * 
     * @type {string}
     * @memberof JobOfferSummary
     */
    'occupationTitle': string;
    /**
     * 
     * @type {string}
     * @memberof JobOfferSummary
     */
    'officeLocation': string;
}
/**
 * 
 * @export
 * @interface MasterSkill
 */
export interface MasterSkill {
    /**
     * 
     * @type {number}
     * @memberof MasterSkill
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MasterSkill
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReport
 */
export interface MonthlyWorkReport {
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReport
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReport
     */
    'status'?: string;
    /**
     * 
     * @type {MonthlyWorkReportProjectMonthlyTerm}
     * @memberof MonthlyWorkReport
     */
    'projectMonthlyTerm'?: MonthlyWorkReportProjectMonthlyTerm;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportDetail
 */
export interface MonthlyWorkReportDetail {
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetail
     */
    'status': string;
    /**
     * 
     * @type {MonthlyWorkReportDetailClientSummary}
     * @memberof MonthlyWorkReportDetail
     */
    'clientSummary': MonthlyWorkReportDetailClientSummary;
    /**
     * 
     * @type {Array<MonthlyWorkReportDetailDailyWorkReports>}
     * @memberof MonthlyWorkReportDetail
     */
    'dailyWorkReports'?: Array<MonthlyWorkReportDetailDailyWorkReports>;
    /**
     * 
     * @type {MonthlyWorkReportDetailProjectMonthlyTerm}
     * @memberof MonthlyWorkReportDetail
     */
    'projectMonthlyTerm': MonthlyWorkReportDetailProjectMonthlyTerm;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportDetailClientSummary
 */
export interface MonthlyWorkReportDetailClientSummary {
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportDetailClientSummary
     */
    'workingMinutes': number;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportDetailDailyWorkReports
 */
export interface MonthlyWorkReportDetailDailyWorkReports {
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'reportOn': string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'breaktimeMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'workStartedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'workEndedAt': string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'dayWorkingMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailDailyWorkReports
     */
    'dailyStatus': string;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportDetailProjectMonthlyTerm
 */
export interface MonthlyWorkReportDetailProjectMonthlyTerm {
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailProjectMonthlyTerm
     */
    'targetMonth'?: string;
    /**
     * 
     * @type {MonthlyWorkReportDetailProjectMonthlyTermProject}
     * @memberof MonthlyWorkReportDetailProjectMonthlyTerm
     */
    'project'?: MonthlyWorkReportDetailProjectMonthlyTermProject;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportDetailProjectMonthlyTermProject
 */
export interface MonthlyWorkReportDetailProjectMonthlyTermProject {
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportDetailProjectMonthlyTermProject
     */
    'title': string;
    /**
     * 
     * @type {MonthlyWorkReportProjectMonthlyTermProjectMemberContract}
     * @memberof MonthlyWorkReportDetailProjectMonthlyTermProject
     */
    'memberContract': MonthlyWorkReportProjectMonthlyTermProjectMemberContract;
    /**
     * 
     * @type {BillUser}
     * @memberof MonthlyWorkReportDetailProjectMonthlyTermProject
     */
    'user': BillUser;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportProjectMonthlyTerm
 */
export interface MonthlyWorkReportProjectMonthlyTerm {
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportProjectMonthlyTerm
     */
    'targetMonth'?: string;
    /**
     * 
     * @type {MonthlyWorkReportProjectMonthlyTermProject}
     * @memberof MonthlyWorkReportProjectMonthlyTerm
     */
    'project'?: MonthlyWorkReportProjectMonthlyTermProject;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportProjectMonthlyTermProject
 */
export interface MonthlyWorkReportProjectMonthlyTermProject {
    /**
     * 
     * @type {string}
     * @memberof MonthlyWorkReportProjectMonthlyTermProject
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyWorkReportProjectMonthlyTermProject
     */
    'useWorkReport': boolean;
    /**
     * 
     * @type {MonthlyWorkReportProjectMonthlyTermProjectMemberContract}
     * @memberof MonthlyWorkReportProjectMonthlyTermProject
     */
    'memberContract': MonthlyWorkReportProjectMonthlyTermProjectMemberContract;
    /**
     * 
     * @type {BillUser}
     * @memberof MonthlyWorkReportProjectMonthlyTermProject
     */
    'user': BillUser;
}
/**
 * 
 * @export
 * @interface MonthlyWorkReportProjectMonthlyTermProjectMemberContract
 */
export interface MonthlyWorkReportProjectMonthlyTermProjectMemberContract {
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportProjectMonthlyTermProjectMemberContract
     */
    'minimumWorkingHours': number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyWorkReportProjectMonthlyTermProjectMemberContract
     */
    'maximumWorkingHours': number;
}
/**
 * 404エラー
 * @export
 * @interface NotFoundError
 */
export interface NotFoundError {
    /**
     * 
     * @type {string}
     * @memberof NotFoundError
     */
    'operationId': string;
    /**
     * 
     * @type {string}
     * @memberof NotFoundError
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface PostSearchingBookmark
 */
export interface PostSearchingBookmark {
    /**
     * 
     * @type {string}
     * @memberof PostSearchingBookmark
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSearchingBookmark
     */
    'word'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostSearchingBookmark
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSearchingBookmark
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'workingDays'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'workStyles'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'categoryIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'prefectureIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'skillIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostSearchingBookmark
     */
    'appealIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface QuestionnaireCount
 */
export interface QuestionnaireCount {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireCount
     */
    'answered'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireCount
     */
    'expired'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireCount
     */
    'unanswered'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionnaireStatus = {
    Unanswered: 'unanswered',
    Expired: 'expired',
    Answered: 'answered'
} as const;

export type QuestionnaireStatus = typeof QuestionnaireStatus[keyof typeof QuestionnaireStatus];


/**
 * 
 * @export
 * @interface QuestionnaireYear
 */
export interface QuestionnaireYear {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireYear
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireYear
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface SearchingBookmark
 */
export interface SearchingBookmark {
    /**
     * 
     * @type {number}
     * @memberof SearchingBookmark
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchingBookmark
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SearchingBookmark
     */
    'query'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Seo
 */
export interface Seo {
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'theme'?: string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'breadcrumb': string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'h1': string;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'prev_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'next_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof Seo
     */
    'canonical_url': string | null;
    /**
     * 
     * @type {SeoContentCategory}
     * @memberof Seo
     */
    'seo_content_category': SeoContentCategory | null;
    /**
     * 
     * @type {SeoContentMasterSkill}
     * @memberof Seo
     */
    'seo_content_master_skill': SeoContentMasterSkill | null;
}
/**
 * 
 * @export
 * @interface SeoContentCategory
 */
export interface SeoContentCategory {
    /**
     * 
     * @type {string}
     * @memberof SeoContentCategory
     */
    'about': string;
    /**
     * 
     * @type {string}
     * @memberof SeoContentCategory
     */
    'required_skill': string;
    /**
     * 
     * @type {Array<ArticlesInformation>}
     * @memberof SeoContentCategory
     */
    'articles'?: Array<ArticlesInformation>;
}
/**
 * 
 * @export
 * @interface SeoContentMasterSkill
 */
export interface SeoContentMasterSkill {
    /**
     * 
     * @type {Array<AboutInformation>}
     * @memberof SeoContentMasterSkill
     */
    'about': Array<AboutInformation>;
    /**
     * 
     * @type {Array<ArticlesInformation>}
     * @memberof SeoContentMasterSkill
     */
    'articles': Array<ArticlesInformation>;
}
/**
 * 
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * 
     * @type {number}
     * @memberof Skill
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    'skill_name': string;
}
/**
 * 
 * @export
 * @interface SkillCategory
 */
export interface SkillCategory {
    /**
     * 
     * @type {number}
     * @memberof SkillCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SkillCategory
     */
    'category_name': string;
    /**
     * 
     * @type {Array<SkillType>}
     * @memberof SkillCategory
     */
    'types': Array<SkillType>;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof SkillCategory
     */
    'skills': Array<Skill>;
}
/**
 * 
 * @export
 * @interface SkillType
 */
export interface SkillType {
    /**
     * 
     * @type {number}
     * @memberof SkillType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SkillType
     */
    'type_name': string;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof SkillType
     */
    'skills': Array<Skill>;
}
/**
 * ログインエラー(401)
 * @export
 * @interface UnauthorizedError
 */
export interface UnauthorizedError {
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedError
     */
    'operationId': string;
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedError
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface UnprocessableEntityError
 */
export interface UnprocessableEntityError {
    /**
     * 
     * @type {string}
     * @memberof UnprocessableEntityError
     */
    'operationId': string;
    /**
     * 
     * @type {string}
     * @memberof UnprocessableEntityError
     */
    'message'?: string;
    /**
     * 
     * @type {PostSearchingBookmark}
     * @memberof UnprocessableEntityError
     */
    'searchingBookmark'?: PostSearchingBookmark;
}
/**
 * 
 * @export
 * @interface UpdateClientContractExtensionRequest
 */
export interface UpdateClientContractExtensionRequest {
    /**
     * 
     * @type {UpdateContractExtensionRequest}
     * @memberof UpdateClientContractExtensionRequest
     */
    'contractExtensionRequest'?: UpdateContractExtensionRequest;
}
/**
 * パスワード変更エラー
 * @export
 * @interface UpdateClientStaffPasswordBadRequest
 */
export interface UpdateClientStaffPasswordBadRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientStaffPasswordBadRequest
     */
    'operationId': UpdateClientStaffPasswordBadRequestOperationIdEnum;
    /**
     * 
     * @type {UpdateClientStaffPasswordBadRequestErrors}
     * @memberof UpdateClientStaffPasswordBadRequest
     */
    'errors': UpdateClientStaffPasswordBadRequestErrors;
}

export const UpdateClientStaffPasswordBadRequestOperationIdEnum = {
    UpdateClientStaffPassword: 'updateClientStaffPassword'
} as const;

export type UpdateClientStaffPasswordBadRequestOperationIdEnum = typeof UpdateClientStaffPasswordBadRequestOperationIdEnum[keyof typeof UpdateClientStaffPasswordBadRequestOperationIdEnum];

/**
 * 
 * @export
 * @interface UpdateClientStaffPasswordBadRequestErrors
 */
export interface UpdateClientStaffPasswordBadRequestErrors {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClientStaffPasswordBadRequestErrors
     */
    'password'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClientStaffPasswordBadRequestErrors
     */
    'passwordConfirmation'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateContractExtensionRequest
 */
export interface UpdateContractExtensionRequest {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateContractExtensionRequest
     */
    'extensionSurveyAnswers'?: { [key: string]: object; };
    /**
     * 
     * @type {UpdateOutsourcingChecked}
     * @memberof UpdateContractExtensionRequest
     */
    'outsourcingChecked'?: UpdateOutsourcingChecked;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UpdateOutsourcingChecked = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type UpdateOutsourcingChecked = typeof UpdateOutsourcingChecked[keyof typeof UpdateOutsourcingChecked];


/**
 * 
 * @export
 * @interface UserEvaluation
 */
export interface UserEvaluation {
    /**
     * 
     * @type {string}
     * @memberof UserEvaluation
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof UserEvaluation
     */
    'percent': number;
}
/**
 * 案件情報
 * @export
 * @interface UserJobOffer
 */
export interface UserJobOffer {
    /**
     * 
     * @type {number}
     * @memberof UserJobOffer
     */
    'id': number;
    /**
     * 案件が募集中かどうか
     * @type {boolean}
     * @memberof UserJobOffer
     */
    'open': boolean;
    /**
     * 新着案件フラグ
     * @type {boolean}
     * @memberof UserJobOffer
     */
    'new': boolean;
    /**
     * 案件名
     * @type {string}
     * @memberof UserJobOffer
     */
    'title': string;
    /**
     * 最高月額単価
     * @type {number}
     * @memberof UserJobOffer
     */
    'maxUnitPrice'?: number;
    /**
     * 業務内容
     * @type {string}
     * @memberof UserJobOffer
     */
    'description': string;
    /**
     * オフィス所在地
     * @type {string}
     * @memberof UserJobOffer
     */
    'officeLocation'?: string;
    /**
     * 最低稼働日数
     * @type {number}
     * @memberof UserJobOffer
     */
    'requiredWorkingDays'?: number;
    /**
     * 
     * @type {UserJobOfferOccupation}
     * @memberof UserJobOffer
     */
    'occupation': UserJobOfferOccupation;
    /**
     * 新スキルのリスト
     * @type {Array<UserJobOfferMasterSkills>}
     * @memberof UserJobOffer
     */
    'masterSkills'?: Array<UserJobOfferMasterSkills>;
    /**
     * こだわりのリスト
     * @type {Array<UserJobOfferAppeals>}
     * @memberof UserJobOffer
     */
    'appeals'?: Array<UserJobOfferAppeals>;
}
/**
 * 
 * @export
 * @interface UserJobOfferAppeals
 */
export interface UserJobOfferAppeals {
    /**
     * 
     * @type {number}
     * @memberof UserJobOfferAppeals
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserJobOfferAppeals
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UserJobOfferMasterSkills
 */
export interface UserJobOfferMasterSkills {
    /**
     * 
     * @type {number}
     * @memberof UserJobOfferMasterSkills
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserJobOfferMasterSkills
     */
    'name'?: string;
}
/**
 * 職種
 * @export
 * @interface UserJobOfferOccupation
 */
export interface UserJobOfferOccupation {
    /**
     * 
     * @type {number}
     * @memberof UserJobOfferOccupation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserJobOfferOccupation
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserSkillRelation
 */
export interface UserSkillRelation {
    /**
     * 
     * @type {number}
     * @memberof UserSkillRelation
     */
    'masterSkillId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSkillRelation
     */
    'year'?: number;
}
/**
 * メールアドレス認証エラー
 * @export
 * @interface VerifyClientStaffEmailBadRequest
 */
export interface VerifyClientStaffEmailBadRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyClientStaffEmailBadRequest
     */
    'operationId': VerifyClientStaffEmailBadRequestOperationIdEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyClientStaffEmailBadRequest
     */
    'error': string;
}

export const VerifyClientStaffEmailBadRequestOperationIdEnum = {
    VerifyClientStaffEmail: 'verifyClientStaffEmail'
} as const;

export type VerifyClientStaffEmailBadRequestOperationIdEnum = typeof VerifyClientStaffEmailBadRequestOperationIdEnum[keyof typeof VerifyClientStaffEmailBadRequestOperationIdEnum];

/**
 * 
 * @export
 * @interface WorkPreference
 */
export interface WorkPreference {
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'start_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPreference
     */
    'min_work_hours_per_week'?: number;
    /**
     * Must be greater than or equal to `min_work_hours_per_week`. 
     * @type {number}
     * @memberof WorkPreference
     */
    'max_work_hours_per_week'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPreference
     */
    'work_days_of_week'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPreference
     */
    'office_days_of_week'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'remote_work_preference'?: WorkPreferenceRemoteWorkPreferenceEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'available_time'?: WorkPreferenceAvailableTimeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPreference
     */
    'desired_monthly_salary'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'additional_conditions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreference
     */
    'full_time_job_introduction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreference
     */
    'temp_job_introduction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'pre_communication_notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPreference
     */
    'excluded_companies'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreference
     */
    'work_from_abroad'?: boolean;
}

export const WorkPreferenceRemoteWorkPreferenceEnum = {
    Flexible: 'flexible',
    OnboardingOnly: 'onboarding_only',
    FullyRemote: 'fully_remote'
} as const;

export type WorkPreferenceRemoteWorkPreferenceEnum = typeof WorkPreferenceRemoteWorkPreferenceEnum[keyof typeof WorkPreferenceRemoteWorkPreferenceEnum];
export const WorkPreferenceAvailableTimeEnum = {
    WeekdayDaytime: 'weekday_daytime',
    EveningOrWeekendMtgFlexible: 'evening_or_weekend_mtg_flexible',
    EveningOrWeekend: 'evening_or_weekend'
} as const;

export type WorkPreferenceAvailableTimeEnum = typeof WorkPreferenceAvailableTimeEnum[keyof typeof WorkPreferenceAvailableTimeEnum];

/**
 * 
 * @export
 * @interface WorkPreferenceForm
 */
export interface WorkPreferenceForm {
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'start_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPreferenceForm
     */
    'min_work_hours_per_week'?: number;
    /**
     * Must be greater than or equal to `min_work_hours_per_week`. 
     * @type {number}
     * @memberof WorkPreferenceForm
     */
    'max_work_hours_per_week'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPreferenceForm
     */
    'work_days_of_week'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPreferenceForm
     */
    'office_days_of_week'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'remote_work_preference'?: WorkPreferenceFormRemoteWorkPreferenceEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'available_time'?: WorkPreferenceFormAvailableTimeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPreferenceForm
     */
    'desired_monthly_salary'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'additional_conditions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreferenceForm
     */
    'full_time_job_introduction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreferenceForm
     */
    'temp_job_introduction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'pre_communication_notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'excluded_companies'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPreferenceForm
     */
    'work_from_abroad'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceForm
     */
    'form_group'?: WorkPreferenceFormFormGroupEnum;
}

export const WorkPreferenceFormRemoteWorkPreferenceEnum = {
    Flexible: 'flexible',
    OnboardingOnly: 'onboarding_only',
    FullyRemote: 'fully_remote'
} as const;

export type WorkPreferenceFormRemoteWorkPreferenceEnum = typeof WorkPreferenceFormRemoteWorkPreferenceEnum[keyof typeof WorkPreferenceFormRemoteWorkPreferenceEnum];
export const WorkPreferenceFormAvailableTimeEnum = {
    WeekdayDaytime: 'weekday_daytime',
    EveningOrWeekendMtgFlexible: 'evening_or_weekend_mtg_flexible',
    EveningOrWeekend: 'evening_or_weekend'
} as const;

export type WorkPreferenceFormAvailableTimeEnum = typeof WorkPreferenceFormAvailableTimeEnum[keyof typeof WorkPreferenceFormAvailableTimeEnum];
export const WorkPreferenceFormFormGroupEnum = {
    CurrentSituation: 'current_situation',
    JobOfferPreference: 'job_offer_preference',
    AdditionalPreferences: 'additional_preferences'
} as const;

export type WorkPreferenceFormFormGroupEnum = typeof WorkPreferenceFormFormGroupEnum[keyof typeof WorkPreferenceFormFormGroupEnum];

/**
 * 
 * @export
 * @interface WorkPreferenceFormAllOf
 */
export interface WorkPreferenceFormAllOf {
    /**
     * 
     * @type {string}
     * @memberof WorkPreferenceFormAllOf
     */
    'form_group'?: WorkPreferenceFormAllOfFormGroupEnum;
}

export const WorkPreferenceFormAllOfFormGroupEnum = {
    CurrentSituation: 'current_situation',
    JobOfferPreference: 'job_offer_preference',
    AdditionalPreferences: 'additional_preferences'
} as const;

export type WorkPreferenceFormAllOfFormGroupEnum = typeof WorkPreferenceFormAllOfFormGroupEnum[keyof typeof WorkPreferenceFormAllOfFormGroupEnum];


/**
 * V1Api - axios parameter creator
 * @export
 */
export const V1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クライアントのメールアドレスとパスワードでログインをする
         * @summary create authenticate token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogin: async (inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/auths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントをログアウトさせる
         * @summary delete authenticate token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/auths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initial invoice information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialInvoiceInformation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/invoice_informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create invoice number
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceNumber: async (inlineObject4?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/invoice_numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索条件を保存する
         * @summary create searching bookmark
         * @param {PostSearchingBookmark} [postSearchingBookmark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSearchingBookmark: async (postSearchingBookmark?: PostSearchingBookmark, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/searching_bookmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSearchingBookmark, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索履歴を保存する
         * @summary create searching history
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSearchingHistory: async (inlineObject3?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/searching_histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobOffersFavorite: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJobOffersFavorite', 'id', id)
            const localVarPath = `/api/v1/users/job_offers/favorites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 掲載案件候補一覧を取得する
         * @summary list job offers
         * @param {string} [salesforceJobId] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminJobOffers: async (salesforceJobId?: string, order?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/job_offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (salesforceJobId !== undefined) {
                localVarQueryParameter['salesforce_job_id'] = salesforceJobId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list appeals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppeals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/appeals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント請求詳細を取得する。
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBill: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientBill', 'id', id)
            const localVarPath = `/api/v1/clients/bills/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントのステータス別請求数を取得する
         * @summary get client bill count per bill status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/bills/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント請求書のPDFを取得する
         * @summary get client ledger sheet
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillLedgerSheet: async (billId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billId' is not null or undefined
            assertParamExists('getClientBillLedgerSheet', 'billId', billId)
            const localVarPath = `/api/v1/clients/bills/{bill_id}/ledger_sheet`
                .replace(`{${"bill_id"}}`, encodeURIComponent(String(billId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントの請求年一覧を取得する
         * @summary list bill accounting years
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillYears: async (order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/bills/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index
         * @param {'confirmRequested' | 'confirmed' | 'paid'} [status] 
         * @param {string} [targetYear] 
         * @param {string} [targetMonth] 
         * @param {'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBills: async (status?: 'confirmRequested' | 'confirmed' | 'paid', targetYear?: string, targetMonth?: string, order?: 'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn', direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/bills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (targetYear !== undefined) {
                localVarQueryParameter['targetYear'] = targetYear;
            }

            if (targetMonth !== undefined) {
                localVarQueryParameter['targetMonth'] = targetMonth;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get client contract extension request
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientContractExtensionRequest: async (accessToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('getClientContractExtensionRequest', 'accessToken', accessToken)
            const localVarPath = `/api/v1/clients/contract_extension_requests/{access_token}`
                .replace(`{${"access_token"}}`, encodeURIComponent(String(accessToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントに紐づく月次作業報告詳細を取得する
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMonthlyWorkReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientMonthlyWorkReport', 'id', id)
            const localVarPath = `/api/v1/clients/monthly_work_reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMonthlyWorkReports: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/monthly_work_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントのアンケート件数を取得する
         * @summary get count of client questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaireCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/questionnaires/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントのアンケート作成年一覧を取得する
         * @summary list years of client questionnaires
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaireYears: async (order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/questionnaires/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約更新の確認一覧を取得する
         * @summary index
         * @param {number} [page] 
         * @param {'unanswered' | 'expired' | 'answered'} [status] 
         * @param {'status' | 'createdAt' | 'expiresAt'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaires: async (page?: number, status?: 'unanswered' | 'expired' | 'answered', order?: 'status' | 'createdAt' | 'expiresAt', direction?: 'asc' | 'desc', targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/questionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (targetYear !== undefined) {
                localVarQueryParameter['targetYear'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get evaluations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/evaluations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobOffersCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary job offers seo
         * @param {Array<number>} [categoryIds] 
         * @param {Array<number>} [skillIds] 
         * @param {number} [page] 
         * @param {Array<number>} [prefectureIds] 
         * @param {string} [searchWord] 
         * @param {Array<number>} [workingDayIds] 
         * @param {Array<number>} [workStyleIds] 
         * @param {Array<number>} [appealIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobOffersSeo: async (categoryIds?: Array<number>, skillIds?: Array<number>, page?: number, prefectureIds?: Array<number>, searchWord?: string, workingDayIds?: Array<number>, workStyleIds?: Array<number>, appealIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/seo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryIds) {
                localVarQueryParameter['category_ids'] = categoryIds.join(COLLECTION_FORMATS.csv);
            }

            if (skillIds) {
                localVarQueryParameter['skill_ids'] = skillIds.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (prefectureIds) {
                localVarQueryParameter['prefecture_ids'] = prefectureIds.join(COLLECTION_FORMATS.csv);
            }

            if (searchWord !== undefined) {
                localVarQueryParameter['search_word'] = searchWord;
            }

            if (workingDayIds) {
                localVarQueryParameter['working_day_ids'] = workingDayIds.join(COLLECTION_FORMATS.csv);
            }

            if (workStyleIds) {
                localVarQueryParameter['work_style_ids'] = workStyleIds.join(COLLECTION_FORMATS.csv);
            }

            if (appealIds) {
                localVarQueryParameter['appeal_ids'] = appealIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list prefectures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefectures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/prefectures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list recommended categories
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedCategories: async (categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/recommended_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryIds) {
                localVarQueryParameter['category_ids'] = categoryIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list recommended skills
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedSkills: async (categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/recommended_skills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryIds) {
                localVarQueryParameter['category_ids'] = categoryIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list skill_categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/skill_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary job offer detail
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOfferDetail: async (jobOfferId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobOfferId' is not null or undefined
            assertParamExists('getUserJobOfferDetail', 'jobOfferId', jobOfferId)
            const localVarPath = `/api/v1/users/job_offers/{job_offer_id}/detail`
                .replace(`{${"job_offer_id"}}`, encodeURIComponent(String(jobOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get job offer summary
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOfferSummary: async (jobOfferId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobOfferId' is not null or undefined
            assertParamExists('getUserJobOfferSummary', 'jobOfferId', jobOfferId)
            const localVarPath = `/api/v1/users/job_offer_summary/{job_offer_id}`
                .replace(`{${"job_offer_id"}}`, encodeURIComponent(String(jobOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list job offers
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働形態
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {'newest_first' | 'min_salary_desc' | 'max_salary_desc'} [order] ソート順
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOffers: async (word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, order?: 'newest_first' | 'min_salary_desc' | 'max_salary_desc', page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (word !== undefined) {
                localVarQueryParameter['word'] = word;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (openonly !== undefined) {
                localVarQueryParameter['openonly'] = openonly;
            }

            if (workingDays) {
                localVarQueryParameter['working_days[]'] = workingDays.join(COLLECTION_FORMATS.csv);
            }

            if (workStyles) {
                localVarQueryParameter['work_styles[]'] = workStyles.join(COLLECTION_FORMATS.csv);
            }

            if (categoryIds) {
                localVarQueryParameter['category_ids[]'] = categoryIds.join(COLLECTION_FORMATS.csv);
            }

            if (prefectureIds) {
                localVarQueryParameter['prefecture_ids[]'] = prefectureIds.join(COLLECTION_FORMATS.csv);
            }

            if (skillIds) {
                localVarQueryParameter['skill_ids[]'] = skillIds.join(COLLECTION_FORMATS.csv);
            }

            if (appealIds) {
                localVarQueryParameter['appeal_ids[]'] = appealIds.join(COLLECTION_FORMATS.csv);
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get job offers count
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働タイプのリスト
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOffersCount: async (word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/job_offers/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (word !== undefined) {
                localVarQueryParameter['word'] = word;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (openonly !== undefined) {
                localVarQueryParameter['openonly'] = openonly;
            }

            if (workingDays) {
                localVarQueryParameter['working_days[]'] = workingDays.join(COLLECTION_FORMATS.csv);
            }

            if (workStyles) {
                localVarQueryParameter['work_styles[]'] = workStyles.join(COLLECTION_FORMATS.csv);
            }

            if (categoryIds) {
                localVarQueryParameter['category_ids[]'] = categoryIds.join(COLLECTION_FORMATS.csv);
            }

            if (prefectureIds) {
                localVarQueryParameter['prefecture_ids[]'] = prefectureIds.join(COLLECTION_FORMATS.csv);
            }

            if (skillIds) {
                localVarQueryParameter['skill_ids[]'] = skillIds.join(COLLECTION_FORMATS.csv);
            }

            if (appealIds) {
                localVarQueryParameter['appeal_ids[]'] = appealIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 関連するお仕事一覧を取得する
         * @summary list related job offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelatedJobOffers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/related_job_offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保存済み検索条件一覧を取得します
         * @summary list searching bookmarks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSearchingBookmarks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/searching_bookmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user skill relations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSkillRelations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/user_skill_relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get work preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPreference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/work_preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update client contract extension request
         * @param {string} accessToken 
         * @param {UpdateClientContractExtensionRequest} [updateClientContractExtensionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientContractExtensionRequest: async (accessToken: string, updateClientContractExtensionRequest?: UpdateClientContractExtensionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('updateClientContractExtensionRequest', 'accessToken', accessToken)
            const localVarPath = `/api/v1/clients/contract_extension_requests/{access_token}`
                .replace(`{${"access_token"}}`, encodeURIComponent(String(accessToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientContractExtensionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ClientStaffのパスワードを設定する
         * @summary update client staff password
         * @param {string} staffToken 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientStaffPassword: async (staffToken: string, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffToken' is not null or undefined
            assertParamExists('updateClientStaffPassword', 'staffToken', staffToken)
            const localVarPath = `/api/v1/clients/staffs/{staff_token}/password`
                .replace(`{${"staff_token"}}`, encodeURIComponent(String(staffToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary put job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobOffersFavorite: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJobOffersFavorite', 'id', id)
            const localVarPath = `/api/v1/users/job_offers/favorites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update user skill relations
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSkillRelations: async (inlineObject5: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject5' is not null or undefined
            assertParamExists('updateUserSkillRelations', 'inlineObject5', inlineObject5)
            const localVarPath = `/api/v1/users/user_skill_relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update work preference
         * @param {WorkPreferenceForm} workPreferenceForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkPreference: async (workPreferenceForm: WorkPreferenceForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPreferenceForm' is not null or undefined
            assertParamExists('updateWorkPreference', 'workPreferenceForm', workPreferenceForm)
            const localVarPath = `/api/v1/users/work_preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workPreferenceForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClientStaffEmail: async (inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/staff/verify_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ClientStaffのアクセストークンを検証する
         * @summary verify client staff access token
         * @param {string} staffToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClientStaffToken: async (staffToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffToken' is not null or undefined
            assertParamExists('verifyClientStaffToken', 'staffToken', staffToken)
            const localVarPath = `/api/v1/clients/staffs/{staff_token}/verify_token`
                .replace(`{${"staff_token"}}`, encodeURIComponent(String(staffToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1Api - functional programming interface
 * @export
 */
export const V1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ApiAxiosParamCreator(configuration)
    return {
        /**
         * クライアントのメールアドレスとパスワードでログインをする
         * @summary create authenticate token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientLogin(inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogin(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントをログアウトさせる
         * @summary delete authenticate token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary initial invoice information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInitialInvoiceInformation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInitialInvoiceInformation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create invoice number
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceNumber(inlineObject4?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoiceNumber(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索条件を保存する
         * @summary create searching bookmark
         * @param {PostSearchingBookmark} [postSearchingBookmark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserSearchingBookmark(postSearchingBookmark?: PostSearchingBookmark, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserSearchingBookmark(postSearchingBookmark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索履歴を保存する
         * @summary create searching history
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserSearchingHistory(inlineObject3?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserSearchingHistory(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobOffersFavorite(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobOffersFavorite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 掲載案件候補一覧を取得する
         * @summary list job offers
         * @param {string} [salesforceJobId] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminJobOffers(salesforceJobId?: string, order?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminJobOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminJobOffers(salesforceJobId, order, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list appeals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppeals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2006>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppeals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント請求詳細を取得する。
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientBill(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientBill(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントのステータス別請求数を取得する
         * @summary get client bill count per bill status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientBillCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientBillCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント請求書のPDFを取得する
         * @summary get client ledger sheet
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientBillLedgerSheet(billId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientBillLedgerSheet(billId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントの請求年一覧を取得する
         * @summary list bill accounting years
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientBillYears(order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillYear>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientBillYears(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index
         * @param {'confirmRequested' | 'confirmed' | 'paid'} [status] 
         * @param {string} [targetYear] 
         * @param {string} [targetMonth] 
         * @param {'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientBills(status?: 'confirmRequested' | 'confirmed' | 'paid', targetYear?: string, targetMonth?: string, order?: 'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn', direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bill>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientBills(status, targetYear, targetMonth, order, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get client contract extension request
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientContractExtensionRequest(accessToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientContractExtensionRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientContractExtensionRequest(accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントに紐づく月次作業報告詳細を取得する
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientMonthlyWorkReport(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyWorkReportDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientMonthlyWorkReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientMonthlyWorkReports(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthlyWorkReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientMonthlyWorkReports(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントのアンケート件数を取得する
         * @summary get count of client questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientQuestionnaireCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientQuestionnaireCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントのアンケート作成年一覧を取得する
         * @summary list years of client questionnaires
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientQuestionnaireYears(order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionnaireYear>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientQuestionnaireYears(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約更新の確認一覧を取得する
         * @summary index
         * @param {number} [page] 
         * @param {'unanswered' | 'expired' | 'answered'} [status] 
         * @param {'status' | 'createdAt' | 'expiresAt'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientQuestionnaires(page?: number, status?: 'unanswered' | 'expired' | 'answered', order?: 'status' | 'createdAt' | 'expiresAt', direction?: 'asc' | 'desc', targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractExtensionRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientQuestionnaires(page, status, order, direction, targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get evaluations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserEvaluation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobOffersCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2005>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobOffersCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary job offers seo
         * @param {Array<number>} [categoryIds] 
         * @param {Array<number>} [skillIds] 
         * @param {number} [page] 
         * @param {Array<number>} [prefectureIds] 
         * @param {string} [searchWord] 
         * @param {Array<number>} [workingDayIds] 
         * @param {Array<number>} [workStyleIds] 
         * @param {Array<number>} [appealIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobOffersSeo(categoryIds?: Array<number>, skillIds?: Array<number>, page?: number, prefectureIds?: Array<number>, searchWord?: string, workingDayIds?: Array<number>, workStyleIds?: Array<number>, appealIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Seo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobOffersSeo(categoryIds, skillIds, page, prefectureIds, searchWord, workingDayIds, workStyleIds, appealIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list prefectures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrefectures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2007>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrefectures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list recommended categories
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedCategories(categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobOfferCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendedCategories(categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list recommended skills
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedSkills(categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MasterSkill>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendedSkills(categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list skill_categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary job offer detail
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJobOfferDetail(jobOfferId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobOfferDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJobOfferDetail(jobOfferId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get job offer summary
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJobOfferSummary(jobOfferId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobOfferSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJobOfferSummary(jobOfferId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list job offers
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働形態
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {'newest_first' | 'min_salary_desc' | 'max_salary_desc'} [order] ソート順
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJobOffers(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, order?: 'newest_first' | 'min_salary_desc' | 'max_salary_desc', page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserJobOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJobOffers(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, order, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get job offers count
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働タイプのリスト
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJobOffersCount(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJobOffersCount(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 関連するお仕事一覧を取得する
         * @summary list related job offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRelatedJobOffers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRelatedJobOffers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保存済み検索条件一覧を取得します
         * @summary list searching bookmarks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSearchingBookmarks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchingBookmark>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSearchingBookmarks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user skill relations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSkillRelations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSkillRelation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSkillRelations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get work preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkPreference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkPreference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update client contract extension request
         * @param {string} accessToken 
         * @param {UpdateClientContractExtensionRequest} [updateClientContractExtensionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientContractExtensionRequest(accessToken: string, updateClientContractExtensionRequest?: UpdateClientContractExtensionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientContractExtensionRequest(accessToken, updateClientContractExtensionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ClientStaffのパスワードを設定する
         * @summary update client staff password
         * @param {string} staffToken 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientStaffPassword(staffToken: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientStaffPassword(staffToken, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary put job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJobOffersFavorite(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJobOffersFavorite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update user skill relations
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserSkillRelations(inlineObject5: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSkillRelation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserSkillRelations(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update work preference
         * @param {WorkPreferenceForm} workPreferenceForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkPreference(workPreferenceForm: WorkPreferenceForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkPreference(workPreferenceForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyClientStaffEmail(inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyClientStaffEmail(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ClientStaffのアクセストークンを検証する
         * @summary verify client staff access token
         * @param {string} staffToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyClientStaffToken(staffToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyClientStaffToken(staffToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1Api - factory interface
 * @export
 */
export const V1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ApiFp(configuration)
    return {
        /**
         * クライアントのメールアドレスとパスワードでログインをする
         * @summary create authenticate token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogin(inlineObject2?: InlineObject2, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.clientLogin(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントをログアウトさせる
         * @summary delete authenticate token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogout(options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.clientLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initial invoice information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialInvoiceInformation(options?: any): AxiosPromise<object> {
            return localVarFp.createInitialInvoiceInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create invoice number
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceNumber(inlineObject4?: InlineObject4, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.createInvoiceNumber(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索条件を保存する
         * @summary create searching bookmark
         * @param {PostSearchingBookmark} [postSearchingBookmark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSearchingBookmark(postSearchingBookmark?: PostSearchingBookmark, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.createUserSearchingBookmark(postSearchingBookmark, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索履歴を保存する
         * @summary create searching history
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSearchingHistory(inlineObject3?: InlineObject3, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.createUserSearchingHistory(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobOffersFavorite(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteJobOffersFavorite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 掲載案件候補一覧を取得する
         * @summary list job offers
         * @param {string} [salesforceJobId] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminJobOffers(salesforceJobId?: string, order?: string, limit?: number, options?: any): AxiosPromise<Array<AdminJobOffer>> {
            return localVarFp.getAdminJobOffers(salesforceJobId, order, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list appeals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppeals(options?: any): AxiosPromise<Array<InlineResponse2006>> {
            return localVarFp.getAppeals(options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント請求詳細を取得する。
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBill(id: number, options?: any): AxiosPromise<BillDetail> {
            return localVarFp.getClientBill(id, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントのステータス別請求数を取得する
         * @summary get client bill count per bill status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillCount(options?: any): AxiosPromise<BillCount> {
            return localVarFp.getClientBillCount(options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント請求書のPDFを取得する
         * @summary get client ledger sheet
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillLedgerSheet(billId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getClientBillLedgerSheet(billId, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントの請求年一覧を取得する
         * @summary list bill accounting years
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBillYears(order?: 'asc' | 'desc', options?: any): AxiosPromise<Array<BillYear>> {
            return localVarFp.getClientBillYears(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index
         * @param {'confirmRequested' | 'confirmed' | 'paid'} [status] 
         * @param {string} [targetYear] 
         * @param {string} [targetMonth] 
         * @param {'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientBills(status?: 'confirmRequested' | 'confirmed' | 'paid', targetYear?: string, targetMonth?: string, order?: 'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn', direction?: 'asc' | 'desc', options?: any): AxiosPromise<Array<Bill>> {
            return localVarFp.getClientBills(status, targetYear, targetMonth, order, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get client contract extension request
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientContractExtensionRequest(accessToken: string, options?: any): AxiosPromise<ClientContractExtensionRequest> {
            return localVarFp.getClientContractExtensionRequest(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントに紐づく月次作業報告詳細を取得する
         * @summary show
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMonthlyWorkReport(id: number, options?: any): AxiosPromise<MonthlyWorkReportDetail> {
            return localVarFp.getClientMonthlyWorkReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMonthlyWorkReports(page?: number, options?: any): AxiosPromise<Array<MonthlyWorkReport>> {
            return localVarFp.getClientMonthlyWorkReports(page, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントのアンケート件数を取得する
         * @summary get count of client questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaireCount(options?: any): AxiosPromise<QuestionnaireCount> {
            return localVarFp.getClientQuestionnaireCount(options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントのアンケート作成年一覧を取得する
         * @summary list years of client questionnaires
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaireYears(order?: 'asc' | 'desc', options?: any): AxiosPromise<Array<QuestionnaireYear>> {
            return localVarFp.getClientQuestionnaireYears(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約更新の確認一覧を取得する
         * @summary index
         * @param {number} [page] 
         * @param {'unanswered' | 'expired' | 'answered'} [status] 
         * @param {'status' | 'createdAt' | 'expiresAt'} [order] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientQuestionnaires(page?: number, status?: 'unanswered' | 'expired' | 'answered', order?: 'status' | 'createdAt' | 'expiresAt', direction?: 'asc' | 'desc', targetYear?: string, options?: any): AxiosPromise<Array<ContractExtensionRequest>> {
            return localVarFp.getClientQuestionnaires(page, status, order, direction, targetYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get evaluations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluations(options?: any): AxiosPromise<Array<UserEvaluation>> {
            return localVarFp.getEvaluations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobOffersCategories(options?: any): AxiosPromise<Array<InlineResponse2005>> {
            return localVarFp.getJobOffersCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary job offers seo
         * @param {Array<number>} [categoryIds] 
         * @param {Array<number>} [skillIds] 
         * @param {number} [page] 
         * @param {Array<number>} [prefectureIds] 
         * @param {string} [searchWord] 
         * @param {Array<number>} [workingDayIds] 
         * @param {Array<number>} [workStyleIds] 
         * @param {Array<number>} [appealIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobOffersSeo(categoryIds?: Array<number>, skillIds?: Array<number>, page?: number, prefectureIds?: Array<number>, searchWord?: string, workingDayIds?: Array<number>, workStyleIds?: Array<number>, appealIds?: Array<number>, options?: any): AxiosPromise<Seo> {
            return localVarFp.getJobOffersSeo(categoryIds, skillIds, page, prefectureIds, searchWord, workingDayIds, workStyleIds, appealIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list prefectures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefectures(options?: any): AxiosPromise<Array<InlineResponse2007>> {
            return localVarFp.getPrefectures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list recommended categories
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedCategories(categoryIds?: Array<string>, options?: any): AxiosPromise<Array<JobOfferCategory>> {
            return localVarFp.getRecommendedCategories(categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list recommended skills
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedSkills(categoryIds?: Array<string>, options?: any): AxiosPromise<Array<MasterSkill>> {
            return localVarFp.getRecommendedSkills(categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list skill_categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillCategories(options?: any): AxiosPromise<Array<SkillCategory>> {
            return localVarFp.getSkillCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary job offer detail
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOfferDetail(jobOfferId: number, options?: any): AxiosPromise<JobOfferDetail> {
            return localVarFp.getUserJobOfferDetail(jobOfferId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get job offer summary
         * @param {number} jobOfferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOfferSummary(jobOfferId: number, options?: any): AxiosPromise<JobOfferSummary> {
            return localVarFp.getUserJobOfferSummary(jobOfferId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list job offers
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働形態
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {'newest_first' | 'min_salary_desc' | 'max_salary_desc'} [order] ソート順
         * @param {number} [page] ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOffers(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, order?: 'newest_first' | 'min_salary_desc' | 'max_salary_desc', page?: number, options?: any): AxiosPromise<Array<UserJobOffer>> {
            return localVarFp.getUserJobOffers(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, order, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get job offers count
         * @param {string} [word] 検索キーワード
         * @param {number} [minPrice] 単価下限
         * @param {number} [maxPrice] 単価上限
         * @param {boolean} [openonly] 募集中のみ表示するかどうか
         * @param {Array<number>} [workingDays] 稼働日数
         * @param {Array<number>} [workStyles] 稼働タイプのリスト
         * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
         * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
         * @param {Array<number>} [skillIds] スキルIDのリスト
         * @param {Array<number>} [appealIds] こだわりIDのリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJobOffersCount(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.getUserJobOffersCount(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 関連するお仕事一覧を取得する
         * @summary list related job offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelatedJobOffers(options?: any): AxiosPromise<Array<JobOffer>> {
            return localVarFp.getUserRelatedJobOffers(options).then((request) => request(axios, basePath));
        },
        /**
         * 保存済み検索条件一覧を取得します
         * @summary list searching bookmarks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSearchingBookmarks(options?: any): AxiosPromise<Array<SearchingBookmark>> {
            return localVarFp.getUserSearchingBookmarks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user skill relations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSkillRelations(options?: any): AxiosPromise<Array<UserSkillRelation>> {
            return localVarFp.getUserSkillRelations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get work preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPreference(options?: any): AxiosPromise<WorkPreference> {
            return localVarFp.getWorkPreference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update client contract extension request
         * @param {string} accessToken 
         * @param {UpdateClientContractExtensionRequest} [updateClientContractExtensionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientContractExtensionRequest(accessToken: string, updateClientContractExtensionRequest?: UpdateClientContractExtensionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateClientContractExtensionRequest(accessToken, updateClientContractExtensionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ClientStaffのパスワードを設定する
         * @summary update client staff password
         * @param {string} staffToken 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientStaffPassword(staffToken: string, inlineObject?: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.updateClientStaffPassword(staffToken, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary put job offers favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobOffersFavorite(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateJobOffersFavorite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update user skill relations
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSkillRelations(inlineObject5: InlineObject5, options?: any): AxiosPromise<Array<UserSkillRelation>> {
            return localVarFp.updateUserSkillRelations(inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update work preference
         * @param {WorkPreferenceForm} workPreferenceForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkPreference(workPreferenceForm: WorkPreferenceForm, options?: any): AxiosPromise<WorkPreference> {
            return localVarFp.updateWorkPreference(workPreferenceForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClientStaffEmail(inlineObject1?: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.verifyClientStaffEmail(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * ClientStaffのアクセストークンを検証する
         * @summary verify client staff access token
         * @param {string} staffToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyClientStaffToken(staffToken: string, options?: any): AxiosPromise<object> {
            return localVarFp.verifyClientStaffToken(staffToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1Api - object-oriented interface
 * @export
 * @class V1Api
 * @extends {BaseAPI}
 */
export class V1Api extends BaseAPI {
    /**
     * クライアントのメールアドレスとパスワードでログインをする
     * @summary create authenticate token
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public clientLogin(inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).clientLogin(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントをログアウトさせる
     * @summary delete authenticate token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public clientLogout(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).clientLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initial invoice information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createInitialInvoiceInformation(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).createInitialInvoiceInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create invoice number
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createInvoiceNumber(inlineObject4?: InlineObject4, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).createInvoiceNumber(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索条件を保存する
     * @summary create searching bookmark
     * @param {PostSearchingBookmark} [postSearchingBookmark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createUserSearchingBookmark(postSearchingBookmark?: PostSearchingBookmark, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).createUserSearchingBookmark(postSearchingBookmark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索履歴を保存する
     * @summary create searching history
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createUserSearchingHistory(inlineObject3?: InlineObject3, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).createUserSearchingHistory(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete job offers favorite
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public deleteJobOffersFavorite(id: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).deleteJobOffersFavorite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 掲載案件候補一覧を取得する
     * @summary list job offers
     * @param {string} [salesforceJobId] 
     * @param {string} [order] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getAdminJobOffers(salesforceJobId?: string, order?: string, limit?: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getAdminJobOffers(salesforceJobId, order, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list appeals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getAppeals(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getAppeals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント請求詳細を取得する。
     * @summary show
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientBill(id: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientBill(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントのステータス別請求数を取得する
     * @summary get client bill count per bill status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientBillCount(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientBillCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント請求書のPDFを取得する
     * @summary get client ledger sheet
     * @param {number} billId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientBillLedgerSheet(billId: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientBillLedgerSheet(billId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントの請求年一覧を取得する
     * @summary list bill accounting years
     * @param {'asc' | 'desc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientBillYears(order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientBillYears(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index
     * @param {'confirmRequested' | 'confirmed' | 'paid'} [status] 
     * @param {string} [targetYear] 
     * @param {string} [targetMonth] 
     * @param {'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn'} [order] 
     * @param {'asc' | 'desc'} [direction] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientBills(status?: 'confirmRequested' | 'confirmed' | 'paid', targetYear?: string, targetMonth?: string, order?: 'status' | 'invoiceNumber' | 'billedOn' | 'billLimitedOn', direction?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientBills(status, targetYear, targetMonth, order, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get client contract extension request
     * @param {string} accessToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientContractExtensionRequest(accessToken: string, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientContractExtensionRequest(accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントに紐づく月次作業報告詳細を取得する
     * @summary show
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientMonthlyWorkReport(id: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientMonthlyWorkReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientMonthlyWorkReports(page?: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientMonthlyWorkReports(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントのアンケート件数を取得する
     * @summary get count of client questionnaires
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientQuestionnaireCount(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientQuestionnaireCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントのアンケート作成年一覧を取得する
     * @summary list years of client questionnaires
     * @param {'asc' | 'desc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientQuestionnaireYears(order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientQuestionnaireYears(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約更新の確認一覧を取得する
     * @summary index
     * @param {number} [page] 
     * @param {'unanswered' | 'expired' | 'answered'} [status] 
     * @param {'status' | 'createdAt' | 'expiresAt'} [order] 
     * @param {'asc' | 'desc'} [direction] 
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getClientQuestionnaires(page?: number, status?: 'unanswered' | 'expired' | 'answered', order?: 'status' | 'createdAt' | 'expiresAt', direction?: 'asc' | 'desc', targetYear?: string, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getClientQuestionnaires(page, status, order, direction, targetYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get evaluations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getEvaluations(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getEvaluations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getJobOffersCategories(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getJobOffersCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary job offers seo
     * @param {Array<number>} [categoryIds] 
     * @param {Array<number>} [skillIds] 
     * @param {number} [page] 
     * @param {Array<number>} [prefectureIds] 
     * @param {string} [searchWord] 
     * @param {Array<number>} [workingDayIds] 
     * @param {Array<number>} [workStyleIds] 
     * @param {Array<number>} [appealIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getJobOffersSeo(categoryIds?: Array<number>, skillIds?: Array<number>, page?: number, prefectureIds?: Array<number>, searchWord?: string, workingDayIds?: Array<number>, workStyleIds?: Array<number>, appealIds?: Array<number>, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getJobOffersSeo(categoryIds, skillIds, page, prefectureIds, searchWord, workingDayIds, workStyleIds, appealIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list prefectures
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getPrefectures(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getPrefectures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list recommended categories
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getRecommendedCategories(categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getRecommendedCategories(categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list recommended skills
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getRecommendedSkills(categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getRecommendedSkills(categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list skill_categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getSkillCategories(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getSkillCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary job offer detail
     * @param {number} jobOfferId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserJobOfferDetail(jobOfferId: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserJobOfferDetail(jobOfferId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get job offer summary
     * @param {number} jobOfferId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserJobOfferSummary(jobOfferId: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserJobOfferSummary(jobOfferId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list job offers
     * @param {string} [word] 検索キーワード
     * @param {number} [minPrice] 単価下限
     * @param {number} [maxPrice] 単価上限
     * @param {boolean} [openonly] 募集中のみ表示するかどうか
     * @param {Array<number>} [workingDays] 稼働日数
     * @param {Array<number>} [workStyles] 稼働形態
     * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
     * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
     * @param {Array<number>} [skillIds] スキルIDのリスト
     * @param {Array<number>} [appealIds] こだわりIDのリスト
     * @param {'newest_first' | 'min_salary_desc' | 'max_salary_desc'} [order] ソート順
     * @param {number} [page] ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserJobOffers(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, order?: 'newest_first' | 'min_salary_desc' | 'max_salary_desc', page?: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserJobOffers(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, order, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get job offers count
     * @param {string} [word] 検索キーワード
     * @param {number} [minPrice] 単価下限
     * @param {number} [maxPrice] 単価上限
     * @param {boolean} [openonly] 募集中のみ表示するかどうか
     * @param {Array<number>} [workingDays] 稼働日数
     * @param {Array<number>} [workStyles] 稼働タイプのリスト
     * @param {Array<number>} [categoryIds] 職種カテゴリIDのリスト
     * @param {Array<number>} [prefectureIds] エリア(都道府県)IDのリスト
     * @param {Array<number>} [skillIds] スキルIDのリスト
     * @param {Array<number>} [appealIds] こだわりIDのリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserJobOffersCount(word?: string, minPrice?: number, maxPrice?: number, openonly?: boolean, workingDays?: Array<number>, workStyles?: Array<number>, categoryIds?: Array<number>, prefectureIds?: Array<number>, skillIds?: Array<number>, appealIds?: Array<number>, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserJobOffersCount(word, minPrice, maxPrice, openonly, workingDays, workStyles, categoryIds, prefectureIds, skillIds, appealIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 関連するお仕事一覧を取得する
     * @summary list related job offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserRelatedJobOffers(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserRelatedJobOffers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保存済み検索条件一覧を取得します
     * @summary list searching bookmarks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserSearchingBookmarks(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserSearchingBookmarks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user skill relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getUserSkillRelations(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getUserSkillRelations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get work preference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getWorkPreference(options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).getWorkPreference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update client contract extension request
     * @param {string} accessToken 
     * @param {UpdateClientContractExtensionRequest} [updateClientContractExtensionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateClientContractExtensionRequest(accessToken: string, updateClientContractExtensionRequest?: UpdateClientContractExtensionRequest, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateClientContractExtensionRequest(accessToken, updateClientContractExtensionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ClientStaffのパスワードを設定する
     * @summary update client staff password
     * @param {string} staffToken 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateClientStaffPassword(staffToken: string, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateClientStaffPassword(staffToken, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary put job offers favorite
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateJobOffersFavorite(id: number, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateJobOffersFavorite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update user skill relations
     * @param {InlineObject5} inlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateUserSkillRelations(inlineObject5: InlineObject5, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateUserSkillRelations(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update work preference
     * @param {WorkPreferenceForm} workPreferenceForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateWorkPreference(workPreferenceForm: WorkPreferenceForm, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateWorkPreference(workPreferenceForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public verifyClientStaffEmail(inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).verifyClientStaffEmail(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ClientStaffのアクセストークンを検証する
     * @summary verify client staff access token
     * @param {string} staffToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public verifyClientStaffToken(staffToken: string, options?: AxiosRequestConfig) {
        return V1ApiFp(this.configuration).verifyClientStaffToken(staffToken, options).then((request) => request(this.axios, this.basePath));
    }
}


