import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { appTheme } from 'spa/config/styled'

type ButtonType = 'NEXT' | 'BACK' | 'DISABLED'
type ButtonSize = 'MEDIUM' | 'LARGE'

export type ModalButtonProps = {
  buttonType: ButtonType,
  buttonSize?: ButtonSize,
  onClick: VoidFunction,
  className?: string,
  disabled?: boolean,
  children: React.ReactNode,
}

type StyledButtonProps = {
  theme: DefaultTheme,
  buttonType: ButtonType,
  buttonSize?: ButtonSize,
}

const StyledButton = styled.button<StyledButtonProps>`
  ${props => {
    switch (props.buttonType) {
      case 'NEXT':
        return `
          background-color: ${props.theme.members.colors.red};
          color: ${props.theme.members.colors.white};
        `
      case 'BACK':
        return `
          background-color: ${props.theme.members.colors.pink};
          color: ${props.theme.members.colors.black._100};
        `
      case 'DISABLED':
        return `
          background-color: ${props.theme.members.colors.lightGray};
          color: ${props.theme.members.colors.white};
          cursor: not-allowed;
        `
      default:
        return `
          background-color: ${props.theme.members.colors.red};
          color: ${props.theme.members.colors.white};
        `
    }
  }};
  
  ${props => {
    switch (props.buttonSize) {
      case 'MEDIUM':
        return mediumSizeButtonStyle
      case 'LARGE':
        return largeSizeButtonStyle
      default:
        return mediumSizeButtonStyle
    }
  }};
};
`
// TODO メディアクエリの共通化次第、そちらを使うよう書き変える
const mediumSizeButtonStyle = `
          width: 160px;
          height: 48px;
          border: none;
          border-radius: 50px;
          text-align: center;
          margin: 0 7.5px 16px 7.5px;  
          font-weight: 700 !important;
          font-size: 12px !important;
          line-height: 12px !important;
          word-break: break-word;
          
          @media (min-width: 576px) {
            margin-bottom: 0;
          }
        `

// TODO メディアクエリの共通化次第、そちらを使うよう書き変える
const largeSizeButtonStyle = `
          width: 100%;
          height: 64px;
          border: none;
          border-radius: 50px;
          text-align: center;
          margin: 0 7.5px 16px 7.5px;
          padding-left: 30px;
          padding-right: 30px;
          font-weight: 700 !important;
          font-size: 12px !important;
          line-height: 12px !important;

          span {
            display: inline-block;
          }
          
          @media (min-width: 576px) {
            width: 500px;
            height: 64px;
            flex-shrink: 0;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 27.2px !important;
            
            span {
              display: inline;
            }
          }
        `

export const StyledModalButton: React.FC<ModalButtonProps> = (props: ModalButtonProps) => {
  return (
    <StyledButton
      theme={appTheme}
      buttonType={props.buttonType}
      buttonSize={props.buttonSize}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </StyledButton>
  )
}
